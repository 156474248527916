<template>
<CRow>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CCol sm="12">
    <CCard>
      <CCardHeader>
        {{title}}
      </CCardHeader>
      <CCardBody>
        <CRow  class="justify-content-center mt-1 mb-4">
          <CCol sm="1">
          </CCol>
          <CCol sm="4">
            <CRow>
              <CCol sm="12">
                <div class="preview">    
                  <picture-input
                    v-if="!isEditMode"
                    ref="imageInput"
                    width="186"
                    height="186"
                    accept="image/jpeg,image/png"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                    :imgSrc="image"
                  >
                  </picture-input>
                  <picture-input
                    v-else
                    ref="imageInput"
                    :imgSrc="image"
                    width="186"
                    height="186"
                    accept="image/jpeg,image/png"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                  >
                  </picture-input>
                  <div v-if="$v.tipoBuque.Route.$error">
                    <div class=" text-invalid-feedback mx-3" v-if="!$v.tipoBuque.Route.isValidFile">
                      {{$t('label.badImage')}}
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="6">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model.trim="$v.tipoBuque.TpVesselName.$model"
                  v-uppercase
                  :invalid-feedback="errorMessage($v.tipoBuque.TpVesselName)"
                  :is-valid="hasError($v.tipoBuque.TpVesselName)"
                  size="sm"
                  :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                  :label="$t('label.name')"
                  :placeholder="$t('label.vesselTypeName')"
                  addLabelClasses="required text-right"
                  maxlength="100"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model.trim="$v.tipoBuque.TpVesselDs.$model"
                  :invalid-feedback="errorMessage($v.tipoBuque.TpVesselDs)"
                  :is-valid="hasError($v.tipoBuque.TpVesselDs)"
                  size="sm"
                  :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                  :label="$t('label.description')"
                  :placeholder="$t('label.vesselTypeDescription')"
                  addLabelClasses="required text-right"
                  maxlength="500"
                />
              </CCol>
              <CCol sm="12" v-if="isEditMode">
                <CSelect
                  :value.sync="tipoBuque.Status"
                  :is-valid="statusSelectColor"
                  size="sm"
                  :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                  :label="$t('label.status')"
                  :placeholder="$t('label.status')"
                  :options="statusOptions"
                  addLabelClasses="text-right"
                  class="mt-1"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="1">
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end align-items-center">
            <!--v-if="!isEditMode" :disabled="isSubmitValid" -->
           <!--
            <CButton
              color="add"
              :disabled="isSubmit"
              class="d-flex align-items-center mr-2"
              @click.stop="submitArchivos"
            >
              <CIcon name="checkAlt"/><span class="ml-1">{{$t('label.accept')}}</span>
              
              <div v-if="!isSubmit">
                <CIcon name="checkAlt"/><span class="ml-1">{{$t('label.accept')}}</span>
              </div>
              <div v-if="isSubmit">
                <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-1">{{$t('label.accept')}}</span>
              </div>
            </CButton>-->


            <CButton
              color="add"
              :disabled="isSubmit"
              class="d-flex align-items-center mr-2"
              @click.stop="isEditMode?statusConfirmation(ogStatus, tipoBuque.Status, submitArchivos, tipoBuque.TpVesselName):submitArchivos()"
            >
                <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
<!--
              <div v-if="!isSubmit">
                <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
              </div>
              <div v-if="isSubmit">
                <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-1">{{$t('button.accept')}}</span>
              </div>-->
            </CButton>
            <CButton
              color="wipe"
              :disabled="isSubmit"
              class="d-flex align-items-center"
              @click.stop="toggle(false)"
            >
              <CIcon name="returnSolid"/><span class="ml-1">{{$t('label.return')}}</span>
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </CCol>
</CRow>  
</template>

<script>
import TipoBuqueValidations from '@/_validations/tipo-buque/TipoBuqueFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import TipoBuqueMixin from '@/_mixins/tipo-buque';
import ModalMixin from '@/_mixins/modal';
import { GenerateImgPlaceholder } from '@/_helpers/funciones';

import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    tipoBuque: {
      TpVesselId: '',
      TpVesselName: '',
      TpVesselDs: '',
      Route: '',
      Status: 1
    },
    image: '/img/uploadPhotoEs.svg',
    ogStatus: 1,
    ogRoute: '',
  }
}

//Methods
function submitArchivos() {
  if(!this.isEditMode) {
    this.submitFileAdd();
  }else{
    if(this.tipoBuque.Route == this.ogRoute)
      this.submit();
    else{
      this.submitFileEdit();
    }
  }
}
function submitFileAdd() {
  try {
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let formData = new FormData();
    formData.append('file1', this.tipoBuque.Route);

    this.$http.file('TpVessel-insert', this.tipoBuque.Route)
    .then((response) => {
      this.tipoBuque.Route = response.data.data.files[0].path;
      this.submit();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function submitFileEdit() {
  try {
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.$http.file('TpVessel-update', this.tipoBuque.Route, this.ogRoute)
    .then((response) => {
      this.tipoBuque.Route = response.data.data.files[0].path;
      this.submit();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}
function submit() {
  try {
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let metodo = this.isEditMode? 'PUT':'POST';
    let ruta = this.isEditMode? 'TpVessel-update':'TpVessel-insert';

    let TpVesselJson = this.isEditMode
      ?{
        TpVesselId: this.tipoBuque.TpVesselId,
        TpVesselName: this.tipoBuque.TpVesselName,
        TpVesselDs: this.tipoBuque.TpVesselDs,
        Route: this.tipoBuque.Route,
        Status: this.tipoBuque.Status,
      }
      :{
        TpVesselName: this.tipoBuque.TpVesselName,
        TpVesselDs: this.tipoBuque.TpVesselDs,
        Route: this.tipoBuque.Route
      };

    this.$http.ejecutar(metodo, ruta, TpVesselJson, { root: 'TpVesselJson' })
    .then(response => {
      this.$emit('submited');
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function toggle(newVal) {
  if(!newVal){
    this.resetForm();
    this.$emit('cancel');
  }
}
function resetForm() {
  this.tipoBuque.TpVesselId = '';
  this.tipoBuque.TpVesselName = '';
  this.tipoBuque.TpVesselDs = '';
  this.tipoBuque.Route = '';
  this.tipoBuque.Status = 1;
  this.ogStatus = 1;
  this.image = '/img/uploadPhotoEs.svg';
  this.$nextTick(() => { this.$v.$reset() })
}
function handleFileUpload(image) {
  this.tipoBuque.Route = this.$refs.imageInput.file;
  this.image = image
}

function loadData() {
  this.isLoadingGeneral = true;

  this.$http.ejecutar('GET', 'TpVessel-by-id', { TpVesselId: this.TpVesselId })
  .then((response) => {
    this.setData(response.data.data[0]);
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}
function setData(newData) {
  this.tipoBuque.TpVesselId = newData.TpVesselId;
  this.tipoBuque.TpVesselName = newData.TpVesselName;
  this.tipoBuque.TpVesselDs = newData.TpVesselDs;
  this.tipoBuque.Route = newData.Route;
  this.tipoBuque.Status = newData.FgActTpVessel?1:0;

  this.ogRoute = newData.Route;
  this.ogStatus = newData.FgActTpVessel?1:0;
  //this.image = newData.Route?`${this.$store.getters["connection/getBase"]}${newData.Route.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder();
  this.$v.$touch();
}

//Computeds:
function isEditMode(){
  return this.TpVesselId != '';
}
function title(){
  return !this.isEditMode
    ? this.$t('label.nuevo')+this.$t('label.vesselType')
    : this.$t('label.edit')+this.$t('label.vesselType')+`: ${this.tipoBuque.TpVesselName}`;
}

function statusSelectColor(){
  return this.tipoBuque.Status === 1;
}

export default {
  name: 'tipo-buque-form',
  mixins: [
    TipoBuqueMixin,
    ModalMixin,
  ],
  props: {
    TpVesselId: {
      type: String,
      default: ''
    }
  },
  components: {
    PictureInput
  },
  data,
  created(){
    if(this.isEditMode){
      this.loadData();
    }else{
      let _lang = this.$i18n.locale;
      this.image = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
    }

  },
  validations: TipoBuqueValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    handleFileUpload,
    submitFileAdd,
    submitFileEdit,
    submitArchivos,
    loadData,
 //   statusConfirmation,
    setData
  },
  computed: {
    isEditMode,
    title,
    statusSelectColor,
  },
  TpVesselId: function(newVesselId) {
    if(newVesselId)
      this.loadData();
  }
}
</script>

<style scoped>
.preview {
  height: 15em;
}
.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
</style>